import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Container from "../components/container"
import DarkBlob from "../components/dark-blob"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ServicesBlob from "../components/services-blob"
import ClassCard from "../components/class-card"
import { CheckMark } from "../components/icons"
import Seo from "../components/seo"
import Button from "../components/button"

const InitialConsultation = [
  "Taking a detailed history",
  "Determining problems and goals for your dog",
  "Management ideas for immediate relief",
  "Written prognosis and suggested training package delivered within seven days of consultation",
]

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter } = markdownRemark
  const { how_it_works, featured_image, is_class } = frontmatter
  const image = getImage(featured_image)

  const number = (title, para, number) => {
    return (
      <div className="flex flex-col md:flex-row items-center md:items-start mb-12">
        <div className="bg-lighterPrimary rounded-full w-[62px] h-[62px] flex items-center justify-center mr-4 ">
          <p className="font-crimson font-semibold text-2xl">{number}</p>
        </div>
        <div className="flex-1 text-center md:text-left">
          <h3 className="text-lightBlack mb-1 font-crimson  normal-case text-2xl tracking-normal		">
            {title}
          </h3>
          <p className="text-gray-600 max-w-xl ">{para}</p>
        </div>
      </div>
    )
  }

  return (
    <Layout>
      <Container>
        <div
          className="text-center pt-24 pb-0 max-w-lg mx-auto"
          style={{ zIndex: 30 }}
        >
          <h3 className="">{frontmatter.is_class ? "Class" : "Service"}</h3>
          <hr className="border border-primary" />
          <h1 className="mb-8 mt-2 normal-case text-4xl tracking-normal font-normal text-lightBlack">
            {frontmatter.title}
          </h1>
          <h2 className="mb-4 normal-case text-3xl tracking-normal font-normal text-lightBlack">
            In order to book {frontmatter.title}, you must first graduate from
            an online class.
          </h2>
          {/* {!frontmatter.bookingLinks && (
            <SmartLink href={frontmatter.slug} className={"mx-auto"}>
              <Button
                disabled={
                  frontmatter.disableButton && frontmatter.showInitialConWarning
                }
                // onClick={() => navigate(`/services/${slug}`)}
                style={{ width: "fit-content", margin: "0px auto" }}
              >
                {frontmatter.buttontext}
              </Button>
            </SmartLink>
          )} */}
          {/* {bookingLinks &&
            bookingLinks.map(link => (
              <div className="mb-4 mx-auto" key={link.button_label}>
                <SmartLink href={link.link} className={"mx-auto"}>
                  <Button
                    disabled={disableButton && showInitialConWarning}
                    // onClick={() => navigate(`/services/${slug}`)}
                    style={{ width: "fit-content", margin: "0px auto" }}
                  >
                    {link.button_label}
                  </Button>
                </SmartLink>
              </div>
            ))} */}
        </div>
      </Container>

      {/* How it works */}
      <Container noMax>
        <div
          className="absolute top-[10%] lg:-top-[30%] w-full h-full lg:h-[150%]"
          style={{ zIndex: 11 }}
        >
          <DarkBlob color={"#FFFBF2"} />
        </div>
        <div
          className="flex flex-col lg:flex-row  items-center relative mt-12 lg:mt-48 max-w-6xl mx-auto"
          style={{ zIndex: 20 }}
        >
          <div className="lg:mr-12 mb-12 px-4 rounded-lg basis-1/2	">
            {/* <StaticImage src={image} className="mt-8 rounded-lg" alt="video" /> */}
            <GatsbyImage
              image={image}
              width={"100%"}
              className={"rounded-lg"}
              alt={`Headshot of happy client`}
            />
          </div>

          <div className="text-lightBlack mx-4 lg:ml-8">
            <h2 className="mb-10 font-normal text-center lg:text-left">
              How It Works
            </h2>
            <div className="flex flex-col md:flex-row items-center md:items-start mb-12">
              <div className="bg-lighterPrimary rounded-full w-[62px] h-[62px] flex items-center justify-center mr-4 ">
                <p className="font-crimson font-semibold text-2xl">{1}</p>
              </div>
              <div className="flex-1 text-center md:text-left">
                <h3 className="text-lightBlack mb-1 font-crimson  normal-case text-2xl tracking-normal		">
                  Take an Online Class
                </h3>
                <p className="text-gray-600 max-w-xl ">
                  To ensure the most effective and tailored experience for our
                  clients, we now require all new clients to complete an online
                  class before enrolling in private dog training sessions.
                  Clients can choose between two options based on their specific
                  needs:{" "}
                  <Link to="/classes/basic-manners-online-class/">
                    Basic Manners
                  </Link>{" "}
                  or{" "}
                  <Link to="/classes/reactive-rehabilitation-level-1/">
                    Reactive Rehabilitation 1.
                  </Link>{" "}
                  This adjustment streamlines our private training by
                  eliminating the need to cover the fundamentals, allowing us to
                  focus on more advanced, personalized techniques during
                  one-on-one sessions.
                </p>
              </div>
            </div>
            {how_it_works.map((how, index) => {
              return number(how.title, how.description, index + 2)
            })}
          </div>
        </div>
      </Container>

      {/* Why and Benefits */}
      <Container>
        <div className="text-lightBlack mt-48 max-w-lg mx-auto px-4 xl:px-0 whitespace-pre-line">
          <h2 className="mb-4 font-semibold">Why {frontmatter.title}?</h2>
          <p className="mb-16">{frontmatter.why_day_training}</p>
          <h2 className="mb-4 font-semibold">Additional Benefits</h2>
          <ul>
            {frontmatter.additional_benefits_2.map(({ bene }) => (
              <li className="flex items-top pb-2  " key={bene}>
                <div className="mt-1 w-7">
                  <CheckMark />
                </div>
                <span className="flex-1">{bene}</span>
              </li>
            ))}
          </ul>
        </div>
      </Container>

      {/* When and Where */}
      {is_class && (
        <Container>
          <div className="text-lightBlack mt-12 max-w-lg mx-auto px-4 xl:px-0 whitespace-pre-line">
            <h2 className="mb-4 font-semibold">When</h2>
            <p className="mb-16">{frontmatter.when}</p>
            <h2 className="mb-4 font-semibold">Where</h2>
            <p className="mb-16">{frontmatter.where}</p>
          </div>
        </Container>
      )}

      {/* Pricing */}
      <Container noMax>
        <div
          className="absolute -top-[5%] w-full h-full"
          style={{ zIndex: 10 }}
        >
          <ServicesBlob />
        </div>
        <div
          className="flex flex-col items-center relative mt-32 pt-24 mb-12 max-w-6xl mx-auto px-4"
          style={{ zIndex: 20 }}
        >
          <h2 className="tracking-wide font-semibold">Pricing</h2>
          {/* {frontmatter.show_initial_consultation && (
            <ClassCard
              title={"Initial Consultation"}
              price={"$95"}
              showIncludes
              benefits={InitialConsultation}
              buttontext="Book Your Time"
              slug={
                "https://pocketsuite.io/book/taylor-wyllie/item/initial-consultation"
              }
            />
          )} */}
          <ClassCard
            title={`${frontmatter.title}${
              frontmatter.show_initial_consultation ? `*` : ``
            }`}
            price={frontmatter.pricing_label}
            showIncludes
            showInitialConWarning={frontmatter.show_initial_consultation}
            benefits={frontmatter.pricing_card_benefits.map(
              bene => bene.includes
            )}
            note={frontmatter.pricing_paragraph}
            slug={`${/classes/}`}
            bookingLinks={frontmatter.booking_links}
            // disableButton
            buttontext="See Online Classes"
          />
          {/* {data.allMarkdownRemark.edges.map(
              ({ node }, index) =>
                node.frontmatter.is_active && (
                  <ClassCard data={node.frontmatter} key={index} />
                )
            )} */}
        </div>
      </Container>
    </Layout>
  )
}

export const Head = ({ data }) => (
  <Seo title={data.markdownRemark.frontmatter.title} />
)

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        featured_image {
          childImageSharp {
            gatsbyImageData
          }
        }
        show_initial_consultation
        is_active
        is_class
        slug
        when
        where
        description
        why_day_training
        additional_benefits_2 {
          bene
        }
        pricing_label
        pricing_paragraph
        plain_label
        starting_at_price
        pricing_card_benefits {
          includes
        }
        benefits {
          benefit
        }
        how_it_works {
          title
          description
        }
        booking_links {
          link
          button_label
        }
      }
    }
  }
`
